import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {faSmile} from '@fortawesome/free-solid-svg-icons'
import {NavLink} from "reactstrap";

import Services from "./Services";


class ServicesList extends React.Component {
    constructor() {
        super();

        this.state = {
            services_list: Services,
            service: {},
            focus_out:false
        };
    }

    componentDidMount() {
        const serviceDetail = document.querySelector('.service-detail');
        // const title = document.querySelector('.service-detail h3');
        serviceDetail.classList.add("d-none");
    }

    service_info = (e, id) => {

        const serviceSection = document.querySelector("#service-section");
        // const parent = e.target.parentNode;

        const serviceDetail = document.querySelector('.service-detail');
        const heading1 = document.querySelector('.service-detail h4');
        const heading2 = document.querySelector('.service-detail h3');
        const description = document.querySelector('.service-detail .service-description');

        const listItem = document.querySelector(".active");
        const services = document.querySelector('#services-section');

        const serviceInner = document.querySelector('.service-inner');
        serviceInner.classList.add('vertical');


        this.setState({service: this.state.services_list[id]});
        // parent.classList.remove("circle-container");


        // if (list.lastElementChild.classList.contains("service-center-logo")) {
        //     list.insertBefore(list.lastElementChild, list.childNodes[0]);
        // }

        if (listItem != null) {
            listItem.classList.remove("active");
            listItem.classList.remove("slideInLeft");
            heading1.classList.remove("slideInLeft");
            heading2.classList.remove("slideInRight");
            description.classList.remove("slideInLeft");
        }

        e.target.className += " active";
        // e.target.className += " slideInLeft";

        setTimeout(() => {
            heading1.className += " slideInLeft";
            heading2.className += " slideInRight";
            description.className += " slideInLeft";
        },100);

        setTimeout(() => {
            if(!this.state.focus_out) {
                serviceDetail.classList.remove("d-none");
            }
        }, 2000);

        services.scrollIntoView({behavior: 'smooth', block: 'start'});
        services.style.minHeight = "1240px";
        services.addEventListener('mouseleave',()=>{
            this.setState({
               focus_out:true
            });
            serviceDetail.classList.add("d-none");
            serviceInner.classList.remove('vertical');

            e.target.classList.remove("active");
            services.style.minHeight = "unset";
        });

        services.addEventListener('mouseenter',()=>{
            this.setState({
                focus_out:false
            });
        })
    };

    render() {
        const serviceItems = Services.map((service, key) => {
            return (
                <li onClick={(e) => this.service_info(e, service.id)}
                    className="service-item"
                    id={service.id} key={key}>
                    <NavLink className="d-flex justify-content-center align-items-center" to={service.serviceUrl}
                             tag={Link}>
                        <img className="img-primary" src={service.imgUrl} alt=""/>
                        <img className="img-secondary" src={service.imgUrl2} alt=""/>
                    </NavLink>
                </li>
            );
        });

        return (
            <>
                <div className="service-inner">
                    <ul>
                        {serviceItems}
                    </ul>

                    <div className="service-center-logo">
                        <img src={require("../../assets/img/brand/logo.png").default} alt=""/>
                    </div>
                </div>


                <div className="service-detail ml-auto col-8 col-md-10 col-lg-9" id="scrollElm">
                    <h3 className="mb-4 text-center">{this.state.service.serviceHeading}</h3>
                    <h4 className="mb-3">{this.state.service.serviceTagline}</h4>
                    <div className="mb-3 service-description"
                         dangerouslySetInnerHTML={{__html: this.state.service.serviceDescription}}/>
                </div>
            </>
        );
    }
}

export default ServicesList;



