import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight, faPhoneAlt, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import SocialIcons from "./SocialIcons";

import {
    Container,
    Row,
    Col,
    NavLink
} from "reactstrap";

const scroll = (e) => {
    const contactUs = document.querySelector( '#contact-us-section' );
    const services = document.querySelector( '#services-section' );
    const home = document.querySelector( '#home-section');
    const aboutUs = document.querySelector( '#about-us-section');

    if(e.target.id === "contact-us"){
        contactUs.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    else if(e.target.id === "services"){
        services.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    else if(e.target.id === "home"){
        home.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    else if (e.target.id === "about-us") {
        aboutUs.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
};

class Footer extends React.Component {

    render() {
        return (
            <>
                <footer id="footer">
                    <div className="overlay"></div>
                    <section className="footer-top py-80">
                        <Container>
                            <Row>
                                <Col md={6} lg={3} className="mb-4 mb-lg-0">
                                    <img className="logo-footer"
                                         src={require("../../assets/img/brand/logo-white.png").default} alt=""/>

                                    <ul className="list-unstyled m-0">
                                        <li>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3"/>
                                            Unit 9, Beaumont, Mayaro
                                        </li>

                                        <li>
                                            <FontAwesomeIcon icon={faEnvelope} className="mr-3"/>
                                            info@360scape.net
                                        </li>

                                        <li>
                                            <FontAwesomeIcon icon={faPhoneAlt} className="mr-3"/>
                                            1 (868) 304-4360
                                        </li>
                                    </ul>
                                </Col>

                                <Col md={6} lg={3} className="ml-auto mb-4 mb-lg-0">
                                    <h3>INFORMATION</h3>

                                    <ul className="list-unstyled list-contact-info">
                                        <li>
                                            <Link to="/"  onClick={scroll} id="home">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Home
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/" onClick={scroll} id="about-us">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                About Us
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/" onClick={scroll} id="contact-us">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Contact Us
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/faqs">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                FAQ's
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/privacy-policy">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Privacy Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/terms-conditions">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Terms and Conditions
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/disclaimer">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Disclaimer
                                            </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3} className="">
                                    <h3>SERVICES</h3>

                                    <ul className="list-unstyled">
                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Pool Cleaning Services
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                General Painting
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Technical Building Maintenance
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Building Cleaning & Janitorial
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Commercial and Residential Drain Cleaning
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3} className="mb-4 mb-md-0 service-footer-col">
                                    <h3>SERVICES</h3>

                                    <ul className="list-unstyled list-contact-info">
                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Building Sanitization Services
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Ventilation and Duct Cleaning
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Commercial / Residential Landscaping
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/" tag={Link} onClick={scroll} id="services">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Power Washing & Surface Treatment
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="footer-bottom py-3">
                        <Container>
                            <Row className="justify-content-between align-items-center">
                                <Col md={5} className="mb-3 mb-md-0">
                                    <div className="copyrights-message">
                                        <p>
                                            <span>© 2021</span>{" "}
                                            <a href="/" target="_blank">360<sup>o</sup> Scape.</a>{" "}
                                            <span>All right reserved.</span>
                                        </p>
                                    </div>
                                </Col>

                                <Col md={3}>
                                    <SocialIcons/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </footer>
            </>
        );
    }
}

export default Footer;
