import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from '@fortawesome/free-solid-svg-icons';
// reactstrap components
import {
    Col,
    Container,
    Row,
    Collapse,
    CardBody,
    Card,
    CardHeader,
} from "reactstrap";
// core components
import NavBarDefault from "./components/common/NavBarDefault";
import FooterDefault from "./components/common/FooterDefault";

//Faqs item list json file
import FaqsList from "./FaqsAccordion";

class Faqs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {collapse: 0, FaqsList: FaqsList};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapse: this.state.collapse === Number(event) ? 0 : Number(event),
        });
    }

    render() {
        const {FaqsList, collapse} = this.state;
        return (
            <>
                <NavBarDefault/>
                <div id="section-faqs" className="section-flat py-80">
                    <div className="section-content">
                        <Container>
                            <Row>
                                <Col md={12} className="mb-5">
                                    <div className="section-heading">
                                        <h1 className="text-center">FAQ's</h1>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    {this.state.FaqsList.map((item, key) => {
                                        return (
                                            <Card key={key}>
                                                <CardHeader onClick={this.toggle} data-event={key}>
                                                   <FontAwesomeIcon icon={faPlus} className="mr-3"/> {item.cardHeader}
                                                </CardHeader>
                                                <Collapse isOpen={collapse === key}>
                                                    <CardBody dangerouslySetInnerHTML={{__html: item.cardText}}/>
                                                </Collapse>
                                            </Card>
                                        );
                                    })}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <FooterDefault/>
            </>
        );
    }
}

export default Faqs;
