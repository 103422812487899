import React from "react";
import {} from "module";


// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

class OurTechnologies extends React.Component {

    render() {
        return (
            <>
                <section className="our-technologies-section py-80">
                    <Container>
                        <Row>
                            <Col md={12} className="mb-5">
                                <div className="section-heading">
                                    <h1 className="text-center"> Why Choose Us </h1>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} className="mb-3 md-mb-0">
                                <div className="technology-description">
                                    <p className="pb-3">
                                        360 Degree Scape Ltd. (360° Scape) is an environmentally focused,
                                        service-oriented company specializing in complete, all-round commercial,
                                        industrial and residential building and facilities maintenance. With combined
                                        industry experience of over forty years, our team of professionals is committed
                                        to providing innovative, cost effective solutions to our clients, with minimal
                                        disruption to day-to-day operations. <br/><br/>

                                        We guarantee the highest quality of work suited to our clients’ needs. At 360°
                                        Scape, we persist in our efforts to deliver unparalleled service based on the
                                        following tenets:

                                    </p>
                                    <ul className="mb-4 mb-sm-0">
                                        <li>Industry-rated processes, procedures and checklists</li>
                                        <li>Authorised service warranties and job completion certificates</li>
                                        <li>Utilization of advanced technology to reduce carbon emissions</li>
                                        <li>Cost effective solutions and integrated services</li>
                                        <li>24-hour service availability to minimize operational downtime</li>
                                        <li>
                                            Utilization of environmentally friendly chemicals and materials to reduce
                                            harm to the environment
                                        </li>
                                        <li>
                                            Passion for community development and commitment to corporate social
                                            responsibility
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className="technology-image">
                                    <img className="w-100" alt="..."
                                         src={require("../../assets/img/theme/why-choose-us.jpg").default}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default OurTechnologies;
