import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Col, Container, NavLink, Row} from "reactstrap";
import Services from "../common/Services";
import {Link} from "react-router-dom";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 1200},
        items: 1,
    },
    desktop: {
        breakpoint: {max: 1200, min: 992},
        items: 1,
    },
    tablet: {
        breakpoint: {max: 992, min: 576},
        items: 1,
    },
    mobile: {
        breakpoint: {max: 576, min: 0},
        items: 1,
    },
};

const slideItems = [
    {
        imgUrl: require("../../assets/img/theme/Landscaping.jpg").default
    },
    {
        imgUrl: require("../../assets/img/theme/Building Sanitizeration.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Drain.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Janitor.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Painting.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Pool.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Power washing.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Repair Man.jpg").default
    },
    {
        imgUrl:require("../../assets/img/theme/Ventilation.jpg").default
    }
];

const CustomCarousel = () => {

    const slides = slideItems.map((item, key) => {
        return (
            <div className="box-preview box-area-destination" key={key}>
                <div className="box-img img-bg">
                    <img
                        src={item.imgUrl}
                        alt=""
                    />
                </div>
            </div>
        );
    });

    return (
        <section className="banner" id="home-section">
            <Carousel responsive={responsive} infinite={true} autoPlay={true}>
                {slides}
            </Carousel>
        </section>
    );
};
export default CustomCarousel;
