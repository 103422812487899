import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight, faPhoneAlt, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import SocialIcons from "./SocialIcons";

import {
    Container,
    Row,
    Col,
    a
} from "reactstrap";


class FooterDefault extends React.Component {
    render() {
        return (
            <>
                <footer id="footer">
                    <div className="overlay"></div>
                    <section className="footer-top py-80">
                        <Container>
                            <Row>
                                <Col md={6} lg={3} className="mb-4 mb-lg-0">
                                    <img className="logo-footer"
                                         src={require("../../assets/img/brand/logo-white.png").default} alt=""/>
                                    {/*<p className="pr-3 mb-3">*/}
                                    {/*    Unit 9, Beaumont, Mayaro*/}
                                    {/*</p>*/}

                                    <ul className="list-unstyled m-0">
                                        <li>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3"/>
                                            Unit 9, Beaumont, Mayaro
                                        </li>

                                        <li>
                                            <FontAwesomeIcon icon={faEnvelope} className="mr-3"/>
                                            info@360scape.net
                                        </li>

                                        <li>
                                            <FontAwesomeIcon icon={faPhoneAlt} className="mr-3"/>
                                            1 (868) 304-4360
                                        </li>
                                    </ul>
                                </Col>

                                <Col md={6} lg={3} className="ml-auto mb-4 mb-lg-0">
                                    <h3>INFORMATION</h3>

                                    <ul className="list-unstyled list-contact-info">
                                        <li>
                                            <Link to="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Home
                                            </Link>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                About Us
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Contact Us
                                            </a>
                                        </li>

                                        <li>
                                            <Link to="/faqs">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                FAQ's
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/privacy-policy">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Privacy Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/terms-conditions">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Terms and Conditions
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/disclaimer">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Disclaimer
                                            </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3} className="">
                                    <h3>SERVICES</h3>

                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Pool Cleaning Services
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                General Painting
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Technical Building Maintenance
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Building Cleaning & Janitorial
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Commercial and Residential Drain Cleaning
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3} className="mb-4 mb-md-0 service-footer-col">
                                    <h3>SERVICES</h3>

                                    <ul className="list-unstyled list-contact-info">
                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Building Sanitization Services
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Ventilation and Duct Cleaning
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Commercial / Residential Landscaping
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <FontAwesomeIcon icon={faCaretRight} className="mr-3"/>
                                                Power Washing & Surface Treatment
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="footer-bottom py-3">
                        <Container>
                            <Row className="justify-content-between align-items-center">
                                <Col md={5} className="mb-3 mb-md-0">
                                    <div className="copyrights-message">
                                        <p>

                                            <span>© 2021</span>{" "}
                                            <a href="/" target="_blank">360<sup>o</sup> Scape.</a>{" "}
                                            <span>All right reserved.</span>
                                        </p>
                                    </div>
                                </Col>

                                <Col md={3}>
                                    <SocialIcons/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </footer>
            </>
        );
    }
}

export default FooterDefault;
