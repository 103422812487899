import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'


const icons = [
    {
        icon: faFacebookF,
        socialUrl: "https://www.facebook.com/360-Scape-Ltd-110058311245492",
    },
    {
        icon: faInstagram,
        socialUrl: "https://instagram.com/360scape_tt?igshid=qo1nexdc0yy2",
    },
    {
        icon: faLinkedinIn,
        socialUrl: "https://www.linkedin.com/company/360-scape-ltd/",
    }
];


class SocialIcons extends React.Component {

    render() {
        const iconList = icons.map((icons, key) => {
            return (
                <li className="list-inline-item" key={key}>
                    <a href={icons.socialUrl} target="_blank">
                        <FontAwesomeIcon icon={icons.icon}/>
                    </a>
                </li>
            );
        });

        return (
            <>
                <ul className="list-unstyled list-inline social-icons m-0">
                    {iconList}
                </ul>
            </>
        );
    }
}

export default SocialIcons;



