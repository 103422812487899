import React from "react";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserShield, faAward, faCogs, faLightbulb} from '@fortawesome/free-solid-svg-icons';

// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";


const items = [
    {
        id: 1,
        imgUrl: require("../../assets/img/theme/Core-Values-02.png").default,
        heading: "Safety",
        paragraph: "At the forefront; No Compromise! Safety will not be compromised.\n" +
            "It is ingrained in our culture and is the driving force that guides our delivery,\n" +
            "choice of technology and commitment to our people, customers and our\n" +
            "communities."
    },
    {
        id: 2,
        imgUrl: require("../../assets/img/theme/Core-Values-03.png").default,
        heading: "Quality",
        paragraph: "An Experience of Excellence from Start to End! We aim to\n" +
            "achieve the highest level of client satisfaction by delivering service\n" +
            "excellence, consistently!"
    },
    {
        id: 3,
        imgUrl: require("../../assets/img/theme/Core-Values-05.png").default,
        heading: "Green Innovation",
        paragraph: "Sustaining Life; Safeguarding our Future. Our passion\n" +
            "is to create and utilise maintenance solutions that protect and improve the\n" +
            "quality of life and our environment."
    },
    {
        id: 4,
        imgUrl: require("../../assets/img/theme/Core-Values-04.png").default,
        heading: "Efficiency",
        paragraph: "Right People, Right Solutions, Right Choice! By understanding\n" +
            "your priorities, we reach beyond boundaries to guarantee on time, on\n" +
            "budget and goal focused service delivery."
    },

];


class OurWork extends React.Component {

    render() {
        const workList = items.map((item) => {
            return (
                <Col md={6} lg={3} className="work-col text-center" key={item.id}>
                    <div className="work-col-content pb-3 pt-4">
                        <div className="work-col-image circle d-flex justify-content-center align-items-center mx-auto">
                            <img src={item.imgUrl} alt=""/>
                        </div>

                        <h4 className="mb-3">{item.heading}</h4>

                        <p className="mb-4 px-3">
                            {item.paragraph}
                        </p>
                    </div>
                </Col>
            );
        });

        return (
            <>
                <section className="py-80 our-work-section">
                    <Container>
                        <Row className="mb-5" >
                            <Col md={12} className="mb-5">
                                <div className="section-heading">
                                    <h1 className="text-center">Core Values</h1>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {workList}
                        </Row>

                    </Container>
                </section>
            </>
        );
    }
}

export default OurWork;
