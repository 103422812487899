import React from "react";
import {} from "module";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import SocialIcons from "../common/SocialIcons";

// reactstrap components
import {Container, Row, Col, Button, Form, FormGroup, Label, Input} from "reactstrap";

class ContactUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            email: '',
            message: '',
            phoneNo: ''
        };
    }

    handleSendMessage(e) {
        e.preventDefault();

        axios.post("https://www.360scape.net/contact.php",
            {
                name: this.state.fullName,
                email: this.state.email,
                phoneNo: this.state.phoneNo,
                message: this.state.message,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }
        )
            .then((response) => {
                alert("Your Message has been sent")
            })
            .catch((error) => {
                alert('Error While Sending Email')
            });
    }

    render() {
        return (
            <>
                <section className="contact-us-section py-80 px-3" id="contact-us-section">
                    <Container>
                        <Row>
                            <Col md={12} className="mb-5">
                                <div className="section-heading">
                                    <h1 className="text-center">Contact Us</h1>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5} lg={4} className="contact-us-col">
                                <div className="contact-detail">
                                    <h2 className="text-white mb-3">Contact Us</h2>
                                    <ul className="contact-info-list list-unstyled mb-5">
                                        <li>
                                            <FontAwesomeIcon icon={faEnvelope} className="mr-3"/>
                                            info@360scape.net
                                        </li>

                                        <li>
                                            <FontAwesomeIcon icon={faPhoneAlt} className="mr-3"/>
                                            1 (868) 304-4360
                                        </li>
                                    </ul>

                                    <SocialIcons/>

                                </div>
                            </Col>

                            <Col md={7} className="get-in-touch">
                                <div className="form-wrapper">
                                    <Form className="contact-us-form">
                                        <FormGroup>
                                            <Label for="fullName">TELL US YOUR NAME <span
                                                className="text-danger">*</span></Label>
                                            <Input type="text" name="fullName" id="fullName" placeholder="Full Name"
                                                   onChange={e => this.setState({fullName: e.target.value})}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="email">ENTER YOUR EMAIL <span
                                                className="text-danger">*</span></Label>
                                            <Input type="email" name="email" id="email"
                                                   placeholder="example@email.com"
                                                   onChange={e => this.setState({email: e.target.value})}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="phoneNo">ENTER PHONE NUMBER</Label>
                                            <Input type="text" name="phoneNo" id="phoneNo"
                                                   placeholder="Enter Phone Number"
                                                   onChange={e => this.setState({phoneNo: e.target.value})}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="message">MESSAGE <span className="text-danger">*</span></Label>
                                            <Input type="textarea" rows="4" name="message" id="message"
                                                   onChange={e => this.setState({message: e.target.value})}
                                                   placeholder="Write Us a Message"/>
                                        </FormGroup>

                                        <div className="submit-btn">
                                            <Button className="btn btn-primary"
                                                    onClick={e => this.handleSendMessage(e)}>Send Message</Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default ContactUs;
