import React from "react";
import {Link} from "react-router-dom";


// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";

const scroll = (e) => {
    const contactUs = document.querySelector( '#contact-us-section' );
    const services = document.querySelector( '#services-section' );
    const home = document.querySelector( '#home-section');
    const aboutUs = document.querySelector( '#about-us-section');

     if(e.target.id === "contact-us"){
        contactUs.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    else if(e.target.id === "services"){
        services.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    else if(e.target.id === "home"){
        home.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    else if (e.target.id === "about-us") {
         aboutUs.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
};

class NavBar extends React.Component {

    render() {
        return (
            <>
                <Navbar className="navbar-main fixed-top" expand="lg" id="navbar-main" >
                    <Container fluid={true}>
                        <NavbarBrand className="ml-3 mr-lg-5 p-0" to="/" tag={Link}>
                            <img alt="..." src={require("../../assets/img/brand/logo.png").default}/>
                        </NavbarBrand>

                        <button className="navbar-toggler" id="navbar_global">
                            <span className="navbar-toggler-icon"/>
                        </button>

                        <UncontrolledCollapse toggler="#navbar_global" navbar>
                            <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                                <NavItem className="active">
                                    <NavLink to="/" tag={Link} onClick={scroll} id="home">
                                        Home
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/" tag={Link} onClick={scroll} id="about-us">
                                        About Us
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/" onClick={scroll} id="services">
                                        Services
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink  to="/" onClick={scroll} id="contact-us">
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default NavBar;
