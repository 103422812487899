import React from "react";

import ServicesList from "../common/ServicesList";
// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// const nbOptions = 10;
const angleStart = -360;

//Rotate animation
const rotate = (li, d) => {

    li.style.transform = 'rotate(' + d + 'deg)';
    li.querySelector('a').style.transform = 'rotate(' + (-d) + 'deg)';
};

// show / hide the options
const toggleOptions = (s) => {

    s.classList.toggle('open');

    const li = document.querySelectorAll('.service-item');

    const deg = s.classList.contains('half') ? 180 / (li.length - 1) : 360 / li.length;

    for (let i = 0; i < li.length; i++) {
        const d = s.classList.contains('half') ? (i * deg) - 90 : i * deg;
        s.classList.contains('open') ? rotate(li[i], d) : rotate(li[i], angleStart);
    }
};

// const handleScroll = () =>{
//     let place = window.scrollY;
//     const serviceSection = document.getElementById('services-section').offsetTop;
//     const serviceInner = document.querySelector('.service-inner');
//
//     if (place > serviceSection) {
//         toggleOptions(serviceInner);
//         window.removeEventListener('scroll', handleScroll);
//     }
// };

// window.addEventListener('scroll', handleScroll);


class OurServices extends React.Component {
componentDidMount() {
    const serviceSection = document.getElementById('services-section').offsetTop;
    const serviceInner = document.querySelector('.service-inner');
    toggleOptions(serviceInner);
}

    render() {
        return (
            <>
                <section className="our-services-section py-80" id="services-section">
                    <Container>
                        <Row className="mb-5" >
                            <Col md={12} className="mb-5">
                                <div className="section-heading">
                                    <h1 className="text-center">Our Services</h1>
                                </div>
                            </Col>
                        </Row>

                        <Row className="circle-wrap justify-content-center position-relative px-3" >
                            <ServicesList/>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default OurServices;
