import React from "react";
import {Link} from "react-router-dom";


// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";



class NavBarDefault extends React.Component {

    render() {
        return (
            <>
                <Navbar className="navbar-main fixed-top" expand="lg" id="navbar-main" >
                    <Container fluid={true}>
                        <NavbarBrand className="ml-3 mr-lg-5 p-0" to="/" tag={Link}>
                            <img alt="..." src={require("../../assets/img/brand/logo.png").default}/>
                        </NavbarBrand>

                        <button className="navbar-toggler" id="navbar_global">
                            <span className="navbar-toggler-icon"/>
                        </button>

                        <UncontrolledCollapse toggler="#navbar_global" navbar>
                            <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                                <NavItem className="active">
                                    <NavLink to="/" tag={Link} id="home">
                                        Home
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/" tag={Link}>
                                        About Us
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/"  tag={Link} id="services">
                                        Services
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink  to="/" tag={Link} id="contact-us">
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default NavBarDefault;
