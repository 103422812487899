import {faSmile} from "@fortawesome/free-solid-svg-icons";

const Services = [
    {
        id: 0,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service08.png").default,
        imgUrl2: require("../../assets/img/theme/service08.png").default,
        serviceHeading: "General Painting Services",
        serviceTagline: "Enhance your building’s facade to ultimately increase the value\n" +
            "of your property.",
        serviceDescription: "<p class='mb-3'>360° Scape offers interior and exterior painting services for your residential and\n" +
            "commercial building improvement projects. We provide alternative, credible\n" +
            "solutions to ensure your building looks its best, with the utilization of an available\n" +
            "kaleidoscope of colours that appeal to all.</p>" + "<h4 class='mb-3'>Our general painting services include " +
            "(but are not limited to):</h4>" + "<ul class='ml-4 mb-3'>\n" +
            "<li>Preparation Procedures to level walls prior to painting</li>\n" +
            "<li>Painting of additional rooms and Repainting of faded walls</li>\n" +
            "<li>Interior and Exterior Painting Reworks</li>\n" +
            "<li>The use of extensively researched quality and value-adding products</li>\n" +
            "</ul>" + "<p>To achieve stakeholders’ satisfaction, we have established effective, collaborative\n" +
            "communication channels with our suppliers and customers. This exchange is greatly\n" +
            "enhanced by the expertise of our NACE certified coating specialists who boast a\n" +
            "collective of over 20 years of experience.</p>"
    },
    {
        id: 1,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service03.png").default,
        imgUrl2: require("../../assets/img/theme/service03.png").default,
        serviceHeading: "Ventilation and Duct Cleaning",
        serviceTagline: "Inhale with confidence knowing that your indoor air-flow channels are free from \n" +
            "dust and contaminants.  \n",
        serviceDescription: "<p class='mb-3'>Air duct cleaning is a fundamental service to ensure the improvement and \n" +
            "maintenance of your indoor air quality.  Scheduling regular duct cleaning services \n" +
            "ultimately reduces HVAC unit inefficiencies in your home or business offering a clean \n" +
            "indoor air supply which optimises the health of all occupants. \n</p>" +
            "<p class='mb-3'>360° Scape offers the following ventilation and duct cleaning services which include \n" +
            "servicing of various HVAC system components for complete system reliability. \n</p>" + "<ul class='ml-4'>\n" +
            "<li>Supply and Return Ducts</li>\n" +
            "<li>Cooling Coils</li>\n" +
            "<li>Diffusers and Grilles</li>\n" +
            "<li>Drip Pans</li>\n" +
            "<li>Fan Housing and Motors</li>\n" +
            "<li>Air Handling Unit Housing</li>\n" +
            "</ul>\n"
    },
    {
        id: 2,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service06.png").default,
        imgUrl2: require("../../assets/img/theme/service06.png").default,
        serviceHeading: "Commercial / Residential Landscaping\n",
        serviceTagline: "Access world class commercial and residential landscape designs at a fraction\n" +
            "of the traditional costs.",
        serviceDescription: "<p class='mb-3'>Using drone technology, coupled with augmented reality and the latest graphic design\n" +
            "programming, our landscape architects will assist you with achieving aesthetically\n" +
            "pleasing surroundings for your customers, community and your family.</p>" + "<h4 class='mb-3'>Our landscaping services " +
            "include (but are not limited to):</h4>" + "<ul class='ml-4'>\n" +
            "<li>Land Sculpting</li>\n" +
            "<li>Tree installation and Shaping</li>\n" +
            "<li>Lawn Implementation, Restoration and Maintenance</li>\n" +
            "<li>Botanical Garden Design, Installation and Maintenance</li>\n" +
            "<li>Water Feature and Fountain Installation and Maintenance</li>\n" +
            "<li>General Ground Maintenance</li>\n" +
            "</ul>"
    },
    {
        id: 3,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service09.png").default,
        imgUrl2: require("../../assets/img/theme/service09.png").default,
        serviceHeading: "Power Washing & Surface Treatment",
        serviceTagline: "Give your premises that needed uplift, without the maintenance downtime.",
        serviceDescription: "<p class='mb-3'>Your property is a valuable investment. Over time, exposed surfaces can become\n" +
            "unattractive due to the build-up of mildew, moss, dirt, grime or other external\n" +
            "environmental factors.\n</p>" +
            "<p class='mb-3'>360° Scape restores surfaces in a natural way using environmentally friendly\n" +
            "chemicals to strip away unwanted build-up around your home or business place,\n" +
            "whether on the ground (horizontal) or at heights (vertical).</p>" + "<h4 class='mb-3'>Our services include:</h4>" +
            "<ul class='ml-4'>\n" +
            "<li>Ground (horizontal)\n" + "<br>" +
            "This encompasses the traditional power washing and surface treatment of your\n" +
            "building exterior and includes perimeter and building walls, sidewalks or other\n" +
            "aspects that lend to your building’s curb appeal.</li>\n" +
            "<li>Vertical\n" + "<br>" +
            "Our team of Rope Access technicians are highly trained and insured to treat\n" +
            "your vertical surfaces without the use of scaffolding structures and other high\n" +
            "cost, time-consuming methods. Your building walls, windows and roof tops\n" +
            "will be rejuvenated and restored to a visually attractive, professional standard.</li>\n"
    },
    {
        id: 4,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service04.png").default,
        imgUrl2: require("../../assets/img/theme/service04.png").default,
        serviceHeading: "Building Sanitization Services",
        serviceTagline: "Your assurance of a clean, safe and healthy environment.",
        serviceDescription: "<p class='mb-3'>In today’s world, health and safety has taken precedence over all other basic\n" +
            "needs changing the way we do business and the way we socialize. 360° Scape\n" +
            "is a commercial cleaning and sanitization service provider that specializes in fast,\n" +
            "wide-ranging solutions and treatments for homeowners, schools, office buildings,\n" +
            "government facilities, apartments and other dwelling places.</p>" + "<p class='mb-3'>360° Scape uses EPA-Approved solutions that minimize the spread of pathogens\n" +
            "and environmentally friendly sanitization products that break down naturally in all\n" +
            "environments.</p>"+"<h4 class='mb-3'>Our building sanitation services include (but are not limited to):</h4>" +
            "<ul class='ml-4'>\n" +
            "<li>Sanitization of Ceiling to Floor</li>\n" +
            "<li>Sanitization of Office Equipment</li>\n" +
            "<li>Steam Cleaning</li>\n" +
            "<li>Laundering of Fabrics</li>\n"+
            "</ul>"
    },
    {
        id: 5,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service02.png").default,
        imgUrl2: require("../../assets/img/theme/service02.png").default,
        serviceHeading: "Building Cleaning & Janitorial Services",
        serviceTagline: "Specialists in all industries providing cleaning solutions suited to your budget.",
        serviceDescription: "<p class='mb-3'>360° Scape confidently provides superior janitorial and cleaning services for\n" +
            "commercial and industrial facilities. We offer flexible, daily and weekly professional\n" +
            "cleaning services that enable us to work with your schedule ensuring that you receive\n" +
            "the best cleaning and janitorial experience.</p>"+"<p class='mb-3'>Our team of professionals are readily available " +
            "to discuss your specific needs to design the best-fitting service plan for your building or facility.</p>"+
            "<h4 class='mb-3'>Our services include (but are not limited to):</h4>"+
            "<ul class='ml-4'>\n" +
            "<li>Recurring Janitorial Cleaning</li>\n" +
            "<li>Disinfection and Sanitization Services</li>\n" +
            "<li>Grime and Stain Removal</li>\n" +
            "<li>Sterilization</li>\n"+
            "<li>Carpet Cleaning and Floor Care</li>\n"+
            "<li>Window Cleaning</li>\n"+
            "</ul>"
    },
    {
        id: 6,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service01.png").default,
        imgUrl2: require("../../assets/img/theme/service01.png").default,
        serviceHeading: "Commercial and Residential Drain Cleaning",
        serviceTagline: "On-call 24 hours a day, 7 days a week for your convenience.",
        serviceDescription: "<p class='mb-3'>Over time, without systematic maintenance, blockages in your drains, sinks and\n" +
            "toilets can occur. Therefore, as a proactive maintenance solution, it is highly\n" +
            "recommended and beneficial that you service your drains periodically.</p>"+
            "<p class='mb-3'>360° Scape utilizes the latest rotor tools and technology such as, video camera\n" +
            "inspections, to enhance the effectiveness of finding clogs and other blockages in\n" +
            "your drainage system.</p>"+"<h4 class='mb-3'>Our services include:</h4>" +
            "<ul class='ml-4'>\n" +
            "<li>Cleaning of Residential and Commercial Drain Systems</li>\n" +
            "<li>Routine Drain Maintenance</li>\n" +
            "<li>Emergency Callouts (real-time) to manage clogged drainage issues.</li>\n" +
            "</ul>"
    },
    {
        id: 7,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service07.png").default,
        imgUrl2: require("../../assets/img/theme/service07.png").default,
        serviceHeading: "Technical Building Maintenance",
        serviceTagline: "Our first-rate safety profile guarantees you a safe working environment.\n",
        serviceDescription: "<p class='mb-3'>360° Scape provides highly trained and competent technicians to evaluate, repair\n" +
            "and maintain plumbing, electrical, Fire & Gas and HVAC systems in your building.\n" +
            "As a professional and proactive service provider, we effectively maintain the general\n" +
            "upkeep of your buildings and anticipate problems before they occur.</p>" +
            "<h4 class='mb-3'>Our Technical Maintenance services include:</h4>"+
            "<ul class='ml-4'>\n" +
            "<li>Lighting repairs and upgrades</li>\n" +
            "<li>Repair of faulty wiring</li>\n" +
            "<li>Repair and maintenance of HVAC Systems</li>\n" +
            "<li>Plumbing repairs</li>\n" +
            "<li>Generator repairs and services</li>\n" +
            "<li>Maintenance of Building Safety Systems – Inclusive of Fire & Gas, Carbon Monoxide\n" +
            "and Smoke Detectors</li>\n" +
            "</ul>"
    },
    {
        id: 8,
        icon: faSmile,
        serviceUrl: "/",
        imgUrl: require("../../assets/img/theme/service05.png").default,
        imgUrl2: require("../../assets/img/theme/service05.png").default,
        serviceHeading: "Pool Cleaning Services",
        serviceTagline: "Safeguarding your health and safety so you can enjoy your long-term investment.\n",
        serviceDescription: "<p class='mb-3'>At 360° Scape, we believe that a clean, functional pool is critical to ensure the safety of\n" +
            "your family and friends while in the water. Our team of pool service technicians are highly\n" +
            "trained to perform the full suite of pool cleaning services to improve the appearance of\n" +
            "your pool surfaces and ensure that your pool and its ambient surroundings are clear,\n" +
            "clean and sanitized.</p>" +
            "<p class='mb-3'>We utilize the best technology and equipment along with proven sterilization methods\n" +
            "guaranteed to eliminate the presence of pathogens, bacteria and other parasites so that\n" +
            "everyone can enjoy the pleasure of your long-term investment.</p>"+
            "<h4 class='mb-3'>Our weekly, full-service, pool cleaning package includes (but is not limited to):</h4>"+
            "<ul class='ml-4'>\n" +
            "<li>Surface skimming</li>\n" +
            "<li>Sweeping and Vacuuming</li>\n" +
            "<li>Cleaning of skimmer and pump baskets</li>\n" +
            "<li>Brushing / sweeping of walls, tiles, steps, benches and surrounding surfaces</li>\n" +
            "<li>Light chemical treatment services</li>\n" +
            "</ul>"
    },
];

export default Services;