import React, { useState } from 'react';

// reactstrap components
import {
    Container,
    Row,
    Col,
    Button, Modal, ModalHeader, ModalBody
} from "reactstrap";

const OurVision = ()=> {

    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);

    const toggle1 = () => setModal1(!modal1);
    const toggle2 = () => setModal2(!modal2);



        return (
            <>
                <section className="our-vision-section py-80" id="about-us-section">
                    <Container>
                        <Row>
                            <Col md={12} className="mb-5">
                                <div className="section-heading">
                                    <h1 className="text-center">About Us</h1>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} className="mb-4">
                                <div className="vision-logo-image pb-5 pb-md-2">
                                    <img alt="..." src={require("../../assets/img/brand/logo.png").default}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="vision d-flex align-items-center mx-auto">
                                    <div className="vision-content text-center">
                                        <h4 className="text-center mx-auto mb-4">Our Vision</h4>
                                        <p className="text-center px-4 mb-3">
                                            To be a market leader in facilities maintenance, providing a “one stop
                                            shop” of unique, quality-driven innovations that save our clients
                                            valuable time and minimise cost.
                                        </p>
                                        <Button className="btn btn-secondary" onClick={toggle1}>Read More >></Button>
                                        <Modal isOpen={modal1} toggle={toggle1} className="modal-dialog-centered vision-modal">
                                            <ModalHeader toggle={toggle1}>Our Vision</ModalHeader>

                                            <ModalBody className="p-4">
                                                To be a market leader in facilities maintenance, providing a “one stop
                                                shop” of unique, quality-driven innovations that save our clients
                                                valuable time and minimise cost.
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="vision d-flex align-items-center mx-auto">
                                   <div className="vision-content text-center">
                                       <h4 className="text-center mx-auto mb-3">Our Mission</h4>
                                       <p className="text-center px-4 mb-4">
                                           We are quality driven, committed to efficiency and fueled by technology
                                           to build a strong reputation of success with our customers that
                                           guarantees exceptional value for money, robust maintenance systems
                                           and processes and end-to-end customer satisfaction.
                                       </p>
                                       <Button className="btn btn-secondary" onClick={toggle2}>Read More >></Button>
                                       <Modal isOpen={modal2} toggle={toggle2} className="modal-dialog-centered vision-modal">
                                           <ModalHeader toggle={toggle2}>Our Mission</ModalHeader>

                                           <ModalBody className="p-4">
                                               We are quality driven, committed to efficiency and fueled by technology
                                               to build a strong reputation of success with our customers that
                                               guarantees exceptional value for money, robust maintenance systems
                                               and processes and end-to-end customer satisfaction.
                                           </ModalBody>
                                       </Modal>
                                   </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </>
        );
    };

export default OurVision;
