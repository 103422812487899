import React from 'react';

import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.css'
import './assets/scss/main.scss';
import Home from './Home';
import Faqs from "./Faqs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import Disclaimer from "./Disclaimer";


ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact render={props => <Home {...props} />}/>
            <Route path="/faqs" exact render={props => <Faqs {...props} />}/>
            <Route path="/privacy-policy" exact render={props => <PrivacyPolicy {...props} />}/>
            <Route path="/terms-conditions" exact render={props => <TermsAndConditions {...props} />}/>
            <Route path="/disclaimer" exact render={props => <Disclaimer {...props} />}/>
            <Redirect to="/"/>
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

