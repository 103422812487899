import React from "react";
import NavBar from "./components/common/NavBar";
import OurServices from "./components/Home/OurServices";
import OurTechnologies from "./components/Home/OurTechnologies";
import OurVision from "./components/Home/OurVision";
import OurWork from "./components/Home/OurWork";
import ContactUs from "./components/Home/ContactUs";
import Footer from "./components/common/Footer";
import CustomCarousel from "./components/Home/CustomCarousel";
import SocialList from "./components/common/SocialList";
import App from "./App";
import Tips from "./components/common/Tips";
// index page sections

class Home extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <NavBar/>
                <CustomCarousel/>
                <OurServices/>
                <OurTechnologies/>
                <OurVision/>
                <OurWork/>
                <ContactUs/>
                <Footer/>
                <SocialList/>
                <Tips/>
            </>
        );
    }
}

export default Home;
