import React, {useState, useRef} from "react";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";

const shareIt = (sh_link) => {
    window.open(sh_link,
        'Share Link',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600,' +
        'top=' + (window.innerHeight / 2 - 200) + ',left=' + (window.innerWidth / 2 - 300));
};

const SocialList = () => {
    const [emailModal, setEmailModal] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');

    const updateEmail = (e) => {
        setEmail(e.target.value);
    };

    const updateName = (e) => {
        setName(e.target.value);
    };

    const updateSubject = (e) => {
        setSubject(e.target.value);
    };
    const toggleEmail = () => setEmailModal(!emailModal);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        axios.post("https://www.360scape.net/sendEmail.php",
            {
                name: name,
                email: email,
                subject: subject,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }
        )
            .then((response) => {
                alert("Your Message has been sent")
            })
            .catch((error) => {
                alert('Error While Sending Email')
            });
    }


    return (
        <>
            <div id="fixed-social">
                <a href="javascript:void(0)" className="fixed-facebook"
                   onClick={()=>shareIt('https://www.facebook.com/sharer/sharer.php?u=https://www.facebook.com/360-Scape-Ltd-110058311245492')}>
                    <FontAwesomeIcon icon={faFacebookF}/>
                    <span>Facebook</span>
                </a>

                <a href="javascript:void(0)" className="fixed-linkedin"
                   onClick={()=>shareIt('https://www.linkedin.com/cws/share?url=https://www.linkedin.com/company/360-scape-ltd/')}>
                    <FontAwesomeIcon icon={faLinkedinIn}/>
                    <span>LinkedIn</span>
                </a>

                {/*    <a href="https://instagram.com/360scape_tt?igshid=qo1nexdc0yy2" className="fixed-instagram" target="_blank">*/}
                {/*        <FontAwesomeIcon icon={faInstagram}/>*/}
                {/*        <span>Instagram</span>*/}
                {/*    </a>*/}

                <div>
                    <a className="fixed-email" onClick={toggleEmail}>
                        <FontAwesomeIcon icon={faEnvelope}/>
                        <span>Email</span>
                    </a>
                </div>
                <Modal isOpen={emailModal} toggle={toggleEmail} className="modal-dialog-centered email-modal">
                    <ModalHeader toggle={toggleEmail}>Share Through Email</ModalHeader>

                    <ModalBody className="p-4">
                        <Form className="email-form">
                            <FormGroup>
                                <Label for="phoneNo">NAME</Label>
                                <Input type="text" placeholder="Enter Name" onChange={e => {
                                    updateName(e)
                                }}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">EMAIL <span className="text-danger">*</span></Label>
                                <Input type="email" placeholder="example@email.com" onChange={e => {
                                    updateEmail(e)
                                }}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="phoneNo">SUBJECT</Label>
                                <Input type="text" placeholder="Enter Subject" onChange={e => {
                                    updateSubject(e)
                                }}
                                />
                            </FormGroup>

                            <div className="submit-btn">
                                <Button className="btn btn-primary" onClick={handleFormSubmit}>Send Email</Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default SocialList;



