import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp,faChevronDown} from '@fortawesome/free-solid-svg-icons';
var tipsList = [
    {
        "name":"Landscaping",
        "desc":"Avoid scalping grass, which is cutting it too short. A scalped lawn is vulnerable to diseases and weed infestation. Scalped turf tends to be weak and sparse, which exposes soil. One of the No. 1 contributors to weed success is exposed soil that allows weeds seed to take root."
    },
    {
        "name":"Gutter Maintenance",
        "desc":"In a downpour, a clogged roof gutter sends a cascade of water down the side of your house, making canyons of your flowerbeds and saturating your foundation. Clean gutters of leaves and debris to help prevent damage to your landscaping and siding, and to head off expensive water damage repairs to your foundation."
    },
    {
        "name":"Gutter Maintenance",
        "desc":"Clean gutters at least once a year -- twice a year if you have overhanging trees. Also, clean clogged gutters after big storms. Clogs often occur where downspouts join the gutter system -- check these areas closely."
    },
    {
        "name":"Drain Maintenance",
        "desc":"Avoid the use of chemical-based drains cleaners as they also degrade the pipes within the drainage system. Enzyme-based cleaners, on the other hand, are a gentler option for your drains. They use enzymes, or bacteria, to break down the organic matters in the pipes."
    },
    {
        "name":"Painting",
        "desc":"Humidity means drips and slow drying, so avoid painting on a rainy day. If you must paint when it’s humid, take your time — and take advantage of slow-drying paint to correct your errors before moving on to the next coat. But don’t overwork, or it will show when you’re finished."
    },
    {
        "name":"Pressure Washing",
        "desc":"Most pressure washers come with a variety of nozzles, usually ranging from 0 to 40 degrees (the angle of the spray stream). The lower the degree, the more powerful and narrow the stream. Start with the highest degree; if it is not cleaning the surface then move to a lower degree. Ease into it, to avoid damaging the surface you’re trying to preserve."
    },
    {
        "name":"Painting",
        "desc":"Ideally, an exterior scheme should have three major parts: A field color that dominates; an accent color that brings shutters, doors, and other smaller parts to life; and the trim color. Once you have chosen an appealing combination of field and trim, make it stand out with an eye-catching accent color."
    },
    {
        "name":"Gutter Maintenance",
        "desc":"When cleaning gutters, eye protection is a must because one never knows what might fly out of the downspout. People have experienced rats, birds, frogs, wasps and bees leaving at high speeds once they start removing a clog, and the last thing they want to have happen is an eye injury."
    },
    {
        "name":"Landscaping",
        "desc":"Branches growing too closely to your home or power lines can wreak havoc down the road. If you have trees on your property, bring in a professional tree trimmer twice a year to assess potential issues and trim down trees. "
    },
    {
        "name":"Gutter Maintenance ",
        "desc":"Take a look around the perimeter of your home to check for downspouts that have become detached from the structure. If downspouts become detached or faulty, water will not get directed away and can lead to water intrusion or even foundation issues. This is a minor fix that can save you major cash down the road."
    },
    {
        "name":"Landscaping",
        "desc":"Keep pruning tool sharp- Dull pruning tools are not only more difficult to use than sharp ones, but they also make rougher cuts in your trees and plants, sometimes negatively affecting the plant's health. Clean cuts created by sharp tools tend to heal quickly, minimizing the possibility of insects and/or disease."
    },
    {
        "name":"Power Washing",
        "desc":"Remember that water and electricity don’t mix well. Cover exterior lights and cable boxes with plastic. Check for over head electrical wires. You will be standing in water most of the time that you are power washing so be safe. You can also remove light fittings and cover the wires in plastic."
    },
    {
        "name":"Power Washing",
        "desc":"Your hose will need to deliver a certain amount of gallons per minute that is specified by the machine you choose. You can test this to see how long it takes to fill a 5 gallon pail, and then compare it to the gallons per minute that the machine needs. Be sure to use a garden hose no longer than 50 feet, and ensure it is kink free."
    },
    {
        "name":"Power Washing",
        "desc":"Choose your cleaner carefully; if you have garden plants near your house, be sure to choose a cleaner that is easy on the plants and also does a great job on the really dirty areas too. There are many products that will do this for you. Scrub the cleaner on the steps to give the cleaner a chance to do its job well."
    },
    {
        "name":"Landscaping",
        "desc":"Dry sand tends to get washed away or swept out of paver brick and stone patio joints. One solution is to use special polymeric sand that binds together when wetted. Premixed sand is the most convenient solution. A bag covers about 120 sq. ft. of paver bricks."
    },
    {
        "name":"Landscaping",
        "desc":"Patios, walkways, courtyards and other hardscape elements add a bit of magic to any yard and reduce landscaping chores. Choose materials and designs that allow rainwater to permeate the soil to irrigate plantings, minimize erosion and prevent runoff. These include organic mulches, gravel, stone and permeable pavers."
    },
    {
        "name":"Landscaping",
        "desc":"Mortar is traditionally used to secure the top courses of stone on a wall. But polyurethane adhesive does the same thing without the hard work and mess of mixing mortar. Polyurethane stays flexible, so it doesn’t crack and fall out like mortar does. Combine stone chips with the adhesive to shim stones to keep them steady until the adhesive cures."
    },
    {
        "name":"Landscaping",
        "desc":"Water-soaked soil is the worst enemy of retaining walls because it exerts enormous pressure behind the wall. Adding good drainage behind block or stone walls is crucial for long-lasting, bulge-free walls. Start by laying perforated plastic drainage tubing along the base of the wall slightly above ground level so it can drain to daylight."
    },
    {
        "name":"Drain Maintenance ",
        "desc":"Next time your drain is clearing slowly, don't buy expensive, chemical drain cleaners. Manually unclog a drain with a drain snake. Just insert into the drain and pull back out with (hopefully) the clog culprit attached. If you don't have or don't want to hassle with one, try using a wet/dry shop vacuum to suck out the clog."
    },
    {
        "name":"Plumbing",
        "desc":"Thread tape is one of the easiest ways to fix a dripping showerhead. Chances are, the leak means the threads aren't connecting tightly. Unscrew the showerhead, apply new tape by wrapping in a clockwise direction, and re-attach to stop the drip. If the showerhead is clogged, soak it in a bowl with warm water and vinegar for about an hour."
    },
    {
        "name":"Landscaping",
        "desc":"Avoid scalping grass, which is cutting it too short. A scalped lawn is vulnerable to diseases and weed infestation. Scalped turf tends to be weak and sparse, which exposes soil. One of the No. 1 contributors to weed success is exposed soil that allows weeds seed to take root."
    },
    {
        "name":"Gutter Maintenance",
        "desc":"In a downpour, a clogged roof gutter sends a cascade of water down the side of your house, making canyons of your flowerbeds and saturating your foundation.Clean gutters of leaves and debris to help prevent damage to your landscaping and siding, and to head off expensive water damage repairs to your foundation."
    },
    {
        "name":"Gutter Maintenance",
        "desc":"Clean gutters at least once a year -- twice a year if you have overhanging trees. Also, clean clogged gutters after big storms. Clogs often occur where downspouts join the gutter system -- check these areas closely."
    },
    {
        "name":"Drain Maintenance",
        "desc":"Avoid the use of chemical-based drains cleaners as they also degrade the pipes within the drainage system. Enzyme-based cleaners, on the other hand, are a gentler option for your drains. They use enzymes, or bacteria, to break down the organic matters in the pipes."
    },
    {
        "name":"Painting",
        "desc":"Humidity means drips and slow drying, so avoid painting on a rainy day. If you must paint when it’s humid, take your time — and take advantage of slow-drying paint to correct your errors before moving on to the next coat. But don’t overwork, or it will show when you’re finished."
    },
    {
        "name":"Pressure Washing",
        "desc":"Most pressure washers come with a variety of nozzles, usually ranging from 0 to 40 degrees (the angle of the spray stream). The lower the degree, the more powerful and narrow the stream. Start with the highest degree; if it is not cleaning the surface then move to a lower degree. Ease into it, to avoid damaging the surface you’re trying to preserve."
    },
    {
        "name":"Painting",
        "desc":"Ideally, an exterior scheme should have three major parts: A field color that dominates; an accent color that brings shutters, doors, and other smaller parts to life; and the trim color. Once you have chosen an appealing combination of field and trim, make it stand out with an eye-catching accent color."
    },
    {
        "name":"Gutter Maintenance",
        "desc":"When cleaning gutters, eye protection is a must because one never knows what might fly out of the downspout. People have experienced rats, birds, frogs, wasps and bees leaving at high speeds once they start removing a clog, and the last thing they want to have happen is an eye injury."
    },
    {
        "name":"Landscaping",
        "desc":"Branches growing too closely to your home or power lines can wreak havoc down the road. If you have trees on your property, bring in a professional tree trimmer twice a year to assess potential issues and trim down trees. "
    },
    {
        "name":"Gutter Maintenance ",
        "desc":"Take a look around the perimeter of your home to check for downspouts that have become detached from the structure. If downspouts become detached or faulty, water will not get directed away and can lead to water intrusion or even foundation issues. This is a minor fix that can save you major cash down the road."
    },
    {
        "name":"Landscaping",
        "desc":"Keep pruning tool sharp- Dull pruning tools are not only more difficult to use than sharp ones, but they also make rougher cuts in your trees and plants, sometimes negatively affecting the plant's health. Clean cuts created by sharp tools tend to heal quickly, minimizing the possibility of insects and/or disease."
    },
];



const tipsOpen = () => {
    const tips = document.getElementById("fixed-tips");
    const tipsOpen = document.getElementById("tips-open");
    const tipsClose = document.getElementById("tips-close");

    tips.classList.toggle("open");
    if(tips.classList.contains("open")){
        tipsOpen.style.display = "none";
        tipsClose.style.display = "block";
    }
    else {
        tipsOpen.style.display = "block";
        tipsClose.style.display = "none";
    }
};

const d = new Date();
const n = d.getDate();


const Tips = () => {
    return (
        <>
            <div className="fixed-tips" id="fixed-tips">
                <div className="tips-content">
                    <h5 className="mb-3">
                        {tipsList[n].name}
                    </h5>

                    <p className="">
                        {tipsList[n].desc}
                    </p>
                </div>

                <span onClick={tipsOpen}>
                    <FontAwesomeIcon icon={faChevronUp} className="mr-3" id="tips-open"/>
                    <FontAwesomeIcon icon={faChevronDown} className="mr-3" id="tips-close" style={{display:"none"}}/>
                    TIPS
                </span>
            </div>
        </>
    );
};

export default Tips;



