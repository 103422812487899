const FaqsList = [
    {
        cardHeader:
            "Where can I find a cost listing of your services?",
        cardText:
            "Because our services are tailored to the needs and circumstances of our clients and their environment, our prices vary and are based on the scope of work outlined.  We encourage your requests for information (RFI’s) and/or requests for quotations (RFQ’s) to begin the dialogue on your service needs.  Feel free to email us at letshelp@360scape.net or visit the Contact Us page on our website at www.360scape.net. ",
    },
    {
        cardHeader: "Does 360° Scape provide special packages for integrated services?",
        cardText:
            "Yes. Based on the combination of services requested, specially priced packages are offered to our clients.",
    },
    {
        cardHeader: "How can I request a site visit?",
        cardText:
            "A site visit can be requested via our website - www.360scape.net under the ‘Contact Us’ section or email us via letshelp@360scape.net.  A client services representative will respond to you within 24 hours of your request.",
    },
    {
        cardHeader: "Are your service staff trained & certified?",
        cardText:
            "All our service delivery employees are trained and certified by recognised accrediting bodies in the relevant skill they execute.  Formal internal training is also conducted by our certified, in-house team as part of our apprenticeship approach to develop non-technical skills.",
    },
    {
        cardHeader: "Can all your services be executed at night time?",
        cardText:
            "Yes. Please note that some services, namely painting and grounds maintenance for e.g., landscaping and grass cutting, will present some limitations due to temperature or weather conditions.",
    },
    {
        cardHeader: "What sectors do you operate in?",
        cardText:
            "360° Scape services the Commercial/Industrial, Residential and Public sectors.",
    },
    {
        cardHeader: "Do you follow any Safety or Quality standards or procedures?",
        cardText:
            "Definitely! Our quality assurance and control procedures are supported by a robust Health, Safety, Environment and Quality (HSEQ) policy that aligns with local and international industrial standards and best practices. ",
    },
    {
        cardHeader: "What industrial standards do you company adhere to?",
        cardText:"360° Scape Ltd. adheres to the following industry standards:"+
            "<ul class='ml-4 mt-2'>\n" +
            "<li class='mb-2'>STOW-TT &ndash; Safe To Work Trinidad &amp; Tobago</li>\n" +
            "<li class='mb-2'>OSHA 2007 &ndash; Occupational Safety and Health</li>\n" +
            "<li class='mb-2'>ISO 9000 &ndash; Quality Management</li>\n" +
            "<li class='mb-2'>ISO 14000 &ndash; Environmental Management</li>\n" +
            "<li class='mb-2'>BS EN12811-1:2003 &ndash; European standard for Temporary Works Equipment, Scaffolds Performance Requirements, and general design</li>\n" +
            "<li class='mb-2'>NACE International</li>"+
            "</ul>",
    },
    {
        cardHeader: "Does 360° Scape have liability coverage?",
        cardText:
            "360° Scape Ltd. is covered by public liability insurance.",
    },
    {
        cardHeader: "What guarantees and assurances are provided for work executed?",
        cardText:
            "Each job/project executed by 360° Scape is formerly closed with a completion certificate. The guarantees and/or warranties afforded will vary based on key job specifications.",
    },
    {
        cardHeader: "Do you make domestic house calls?",
        cardText:
            "This service will soon be available to our clients.  Stay tuned to our website and social media pages for updates.",
    },
    {
        cardHeader: "Do you respond to emergency callouts?",
        cardText:
            "360° Scape understands that the unexpected happens at the most inconvenient times. Our operations are designed to facilitate emergency requests which can be made using our client services helpline: 1 (868) 304-4360.",
    },
    {
        cardHeader: "How can I become a part of the 360° Scape team?",
        cardText:
            "Submit your job applications or updated résumés to careers@360scape.net.  Stay tuned to our website and social media pages for updates on job postings.",
    },
    {
        cardHeader: "What Corporate Social Responsibility activities do you support or promote?",
        cardText:
            "Our core values of Safety, Quality, Efficiency and Green Innovation not only define the way we deliver value to our customers, but also our care for the environment and support for our community."+
            "<ul class='ml-4 mt-2'>\n" +
            "<li class='mb-2'>Beach cleanup campaigns</li>\n" +
          "<li class='mb-2'>Natural Disaster relief efforts to support impacted families and businesses</li>\n" +
          "<li class='mb-2'>Environmental preservation initiatives</li>\n" +
          "<li class='mb-2'>Recycling campaigns and initiatives</li>\n" +
          "</ul>",
    },
];

export default FaqsList;
